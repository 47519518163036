import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useHttp } from "../../hooks/http.hook";
import TableWrap from "../components/Table/TabelWrap";
import SimpleModal from '../components/SimpleModal/SimpleModal'
import tableCss from '../components/Table/style.module.scss'
import { flatten } from 'flat'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import useAuth from "../../hooks/useAuth.hook";
import { InputBase } from "@mui/material";
import Preloader from '../components/Preloader'
import s from './Holder.module.scss'
import NumberDetails from "../components/NumberDetails/NumberDetails";
import { Tooltip } from 'react-tooltip'
import UrlTooltip from "../components/UrlTooltip";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


const Holder = () => {
    const auth = useAuth()
    const { request, loading } = useHttp()
    const [leads, setLeads] = React.useState([])
    const [nameFilter, setNameFilter] = React.useState('')

    const headers = ['Created At', 'First Name', 'Last Name', 'Email', 'Phone', 'Ref', 'Link']

    const getLeads = async (e) => {
        const res = await request(`/api/main/holder/leads`)
        setLeads(res)
    }

    React.useEffect(() => {
        getLeads()
    }, [])

    const nameFilterHandler = (event) => {
        setNameFilter(event.target.value)
    }

    const detailsHandler = (lead) => {
        const partners = {
            'axela': 'message-warning',
            'cpabandtracker': 'message-info',
            'aivix': 'message-danger',
            'cryp.im': 'message-attantion',
            'trump': 'message-dark'
        }
        return partners[lead] || ''
    }

    const handleDelete = async (id) => {
        try {
            await request(`/api/main/holder/remove/lead/${id}`, 'POSt', {}, { Authorization: `Bearer ${auth.token}` })
            setLeads(leads.filter(lead => lead.id !== id));
        } catch (error) {
            console.error(error);
        }
    };

    return <div className="card" >
        <h2>Leads in Holder</h2>
        {console.log(leads)}
        <Paper sx={{ p: '2px 4px', m: 2, display: 'flex', alignItems: 'center' }}>
            <InputBase
                type="search"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Name, Last Name or Email"
                onChange={nameFilterHandler}
                value={nameFilter}
            />
        </Paper>
        {loading && <Preloader />}
        {!loading && leads.length === 0 && <h2>No Results</h2>}
        {!loading && leads.length > 0 && <TableWrap>
            <div className={tableCss.table}>
                <div className={tableCss.header} style={{ "gridTemplateColumns": `90px repeat(4,minmax(100px, 1fr)) 200px 100px 90px` }}>
                    {headers.map(header => <div>
                        {header}
                    </div>)}
                </div>
                <div className={tableCss.content}>
                    {leads
                        .filter(lead => (lead.first_name + " " + lead.last_name + " " + lead.email).includes(nameFilter))
                        .map((lead, index) => <div style={{ "gridTemplateColumns": `25px 40px 90px repeat(4,minmax(100px, 1fr)) 200px 100px 90px`, padding: '8px 0' }}>
                            <div onDoubleClick={() => handleDelete(lead.id)} style={{ color: 'red', cursor: 'pointer' }}>✕</div>
                            <div>{index + 1}</div>
                            <div>{new Date(lead['createdAt']).toLocaleString('uk-UA')}</div>
                            <div>{lead['first_name']}</div>
                            <div>{lead['last_name']}</div>
                            <div>{lead['email']}</div>
                            <div><NumberDetails phoneNumber={lead['phone']} /></div>
                            <div style={{
                                maxHeight: 25,
                                overflow: 'hidden',
                                position: 'relative',
                            }}> <UrlTooltip url={lead['ref']} />
                            </div>
                            <div>
                                {lead['utm'] && <div style={{ color: '#337ab7', fontSize: '12px' }}>{lead['utm']}</div>}
                                <Link to={`/offers/${lead['Link'].OfferId}/add?search=${lead['Link'].name}`} >{lead['Link'].name}</Link>
                                {lead['campaign'] && <div style={{ color: '#b73333', fontSize: '12px' }}>{lead['campaign']}</div>}
                            </div>
                            <div className={`message ${detailsHandler(lead?.Link?.Offer?.Partner.name || '')}`} style={{ margin: 0, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} title={`${lead?.Link?.Offer?.Partner.name ?? ''}`}>{lead?.Link?.Offer?.Partner.name ?? ''}</div>

                            <div className={s.transferWrap}>
                                {lead?.Link?.Holders.map(el => <div className={`message message-dark`} style={{ marginBottom: '2px' }}>
                                    <span>{el.holder_from} - {el.holder_to}</span>
                                </div>)}
                            </div>

                            <div className={s.transferWrap}>
                                {lead?.Link?.Transfers.map(el => <div className={`message ${detailsHandler(el.transfer_road)}`} >
                                    <span>{el.transfer_from} - {el.transfer_to}</span>
                                    {console.log(el.transfer_road)}
                                    <span> → {el.transfer_road}</span>
                                </div>)}
                            </div>

                        </div>)}
                </div>
            </div>
        </TableWrap>}
    </div>
}

export default Holder